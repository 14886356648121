import React from 'react';
import { Box, Container, Typography, Button, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link as ScrollLink } from 'react-scroll';

const ScrollButton = ({ to, variant, children, isDark }) => (
  <ScrollLink to={to} spy={true} smooth={true} offset={-70} duration={500}>
    <Button
      variant={variant}
      size="large"
      sx={{
        px: { xs: 3, md: 4 },
        py: { xs: 1.25, md: 1.5 },
        fontSize: { xs: '1rem', md: '1.1rem' },
        fontWeight: 600,
        borderRadius: '12px',
        position: 'relative',
        overflow: 'hidden',
        ...(variant === 'contained' && {
          background: isDark
            ? 'linear-gradient(135deg, #60a5fa 0%, #2563eb 100%)'
            : 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
          '&:hover': {
            background: isDark
              ? 'linear-gradient(135deg, #60a5fa 0%, #2563eb 100%)'
              : 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
            filter: 'brightness(1.1)',
            transform: 'translateY(-2px)',
          },
        }),
        ...(variant === 'outlined' && {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
            borderColor: isDark ? '#60a5fa' : '#2563eb',
            transform: 'translateY(-2px)',
          },
        }),
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    >
      {children}
    </Button>
  </ScrollLink>
);

function Hero() {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <Box
      component="section"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        background: isDark
          ? 'linear-gradient(135deg, #0f172a 0%, #1e293b 100%)'
          : 'linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%)',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: isDark
            ? 'radial-gradient(circle at 20% 30%, rgba(96, 165, 250, 0.08) 0%, rgba(0,0,0,0) 70%)'
            : 'radial-gradient(circle at 20% 30%, rgba(37, 99, 235, 0.1) 0%, rgba(0,0,0,0) 70%)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Typography
            component={motion.h1}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            variant="h1"
            sx={{
              fontSize: { xs: 'clamp(2.5rem, 8vw, 4rem)', md: 'clamp(3rem, 8vw, 4.5rem)' },
              fontWeight: 800,
              background: isDark
                ? 'linear-gradient(135deg, #60a5fa 0%, #3b82f6 100%)'
                : 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: { xs: 'center', md: 'left' },
              letterSpacing: '-0.02em',
              lineHeight: 1.2,
              mb: { xs: 2, md: 3 },
            }}
          >
            Tyler McGrath
          </Typography>

          <Typography
            component={motion.h2}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            variant="h2"
            sx={{
              fontSize: { xs: 'clamp(1.5rem, 4vw, 2.5rem)', md: 'clamp(2rem, 4vw, 2.75rem)' },
              fontWeight: 700,
              color: theme.palette.text.secondary,
              textAlign: { xs: 'center', md: 'left' },
              letterSpacing: '-0.01em',
              mb: { xs: 3, md: 4 },
            }}
          >
            AI Engineer & Educator
          </Typography>

          <Typography
            component={motion.p}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            variant="body1"
            sx={{
              fontSize: { xs: '1.1rem', md: '1.25rem' },
              color: theme.palette.text.secondary,
              maxWidth: '600px',
              lineHeight: 1.8,
              textAlign: { xs: 'center', md: 'left' },
              mb: { xs: 4, md: 6 },
              marginLeft: { xs: 'auto', md: 0 },
              marginRight: { xs: 'auto', md: 0 },
            }}
          >
            Passionate about leveraging artificial intelligence to solve real-world problems
            and sharing knowledge through education.
          </Typography>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            sx={{
              display: 'flex',
              gap: { xs: 2, md: 3 },
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <ScrollButton
              to="projects"
              variant="contained"
              isDark={isDark}
            >
              View Projects
            </ScrollButton>

            <ScrollButton
              to="contact"
              variant="outlined"
              isDark={isDark}
            >
              Contact Me
            </ScrollButton>
          </Box>
        </Box>
      </Container>

      <ScrollLink to="about" spy={true} smooth={true} offset={-70} duration={500}>
        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            bottom: 40,
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }}
          initial={{ opacity: 0 }}
          animate={{ 
            opacity: 1,
            y: [0, 10, 0],
          }}
          transition={{
            opacity: { duration: 0.8, delay: 1 },
            y: {
              duration: 1.5,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
            },
          }}
          whileHover={{ scale: 1.1 }}
        >
          <KeyboardArrowDownIcon
            sx={{
              fontSize: '2.5rem',
              color: theme.palette.primary.main,
              filter: isDark ? 'brightness(1.2)' : 'none',
              transition: 'all 0.3s ease',
            }}
          />
        </Box>
      </ScrollLink>
    </Box>
  );
}

export default Hero;
