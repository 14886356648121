import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';

function About() {
  const features = [
    {
      icon: <CodeIcon sx={{ fontSize: 40 }} />,
      title: 'Self-Taught Developer',
      description: 'Passionate about coding and technology, with a focus on creating impactful solutions.',
    },
    {
      icon: <SchoolIcon sx={{ fontSize: 40 }} />,
      title: 'Education Innovator',
      description: 'Revolutionizing learning through AI-driven personalization at Papert.',
    },
    {
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      title: 'Entrepreneur',
      description: 'Founded multiple successful ventures including Papert and Genius Circus.',
    },
  ];

  return (
    <Box
      id="about"
      sx={{
        py: 12,
        backgroundColor: 'background.paper',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              mb: 6,
              color: 'primary.main',
            }}
          >
            About Me
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h5" sx={{ mb: 3 }}>
                My Journey
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                As a self-taught programmer and entrepreneur, I've dedicated my career to making education 
                and mental health resources more accessible through technology. My journey began with a 
                simple belief: everyone deserves access to quality education and support.
              </Typography>
              <Typography variant="body1">
                Through Papert, I'm working to personalize education using AI, while Genius Circus focuses 
                on simplifying business operations. I'm particularly proud of Joan's Help, which makes mental 
                health resources more accessible to those who need them.
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {features.map((feature, index) => (
                <Grid item xs={12} key={index}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={2}
                      sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          transition: 'transform 0.3s ease-in-out',
                        },
                      }}
                    >
                      <Box sx={{ color: 'primary.main' }}>
                        {feature.icon}
                      </Box>
                      <Box>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                          {feature.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {feature.description}
                        </Typography>
                      </Box>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
