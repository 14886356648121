import React from 'react';
import { Box, Container, Typography, Grid, Link, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';

function Contact() {
  const contactInfo = [
    {
      icon: <LinkedInIcon sx={{ fontSize: 40 }} />,
      title: 'LinkedIn',
      link: 'https://linkedin.com/in/tyler730',
      text: 'Connect with me',
    },
    {
      icon: <EmailIcon sx={{ fontSize: 40 }} />,
      title: 'Email',
      link: 'mailto:tyler@papert.ai',
      text: 'tyler@papert.ai',
    },
    {
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      title: 'Company',
      link: 'https://www.papert.io',
      text: 'www.papert.io',
    },
  ];

  return (
    <Box
      id="contact"
      sx={{
        py: 12,
        backgroundColor: 'background.default',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              mb: 6,
              color: 'primary.main',
            }}
          >
            Get in Touch
          </Typography>
        </motion.div>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{
            maxWidth: 800,
            margin: '0 auto',
          }}
        >
          {contactInfo.map((info, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      transition: 'transform 0.3s ease-in-out',
                    },
                  }}
                >
                  <IconButton
                    component={Link}
                    href={info.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'primary.main',
                      mb: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(41, 98, 255, 0.1)',
                      },
                    }}
                  >
                    {info.icon}
                  </IconButton>
                  <Typography variant="h6" gutterBottom>
                    {info.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component={Link}
                    href={info.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    {info.text}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            sx={{ mt: 8 }}
          >
            Let's collaborate on making education and mental health resources more accessible!
          </Typography>
        </motion.div>
      </Container>
    </Box>
  );
}

export default Contact;
