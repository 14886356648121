import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { motion } from 'framer-motion';

function Projects() {
  const projects = [
    {
      title: 'AI Education & Mentoring',
      description: 'Helping individuals and organizations understand and implement AI solutions. Offering personalized guidance on leveraging AI for learning and growth.',
      image: '/ai-education.jpg',
      link: '#contact',
    },
    {
      title: 'Technical Consulting',
      description: 'Providing expert advice on technology implementation, system architecture, and digital transformation strategies.',
      image: '/consulting.jpg',
      link: '#contact',
    },
    {
      title: 'Mental Health Tech Solutions',
      description: 'Creating and implementing technology solutions that make mental health support more accessible and effective.',
      image: '/mental-health.jpg',
      link: '#contact',
    },
  ];

  return (
    <Box
      id="projects"
      sx={{
        py: 12,
        backgroundColor: 'background.default',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              mb: 2,
              color: 'primary.main',
            }}
          >
            How I Can Help
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              mb: 6,
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Leveraging technology and experience to support your growth and success
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      transition: 'transform 0.3s ease-in-out',
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={project.image}
                    alt={project.title}
                    sx={{
                      objectFit: 'cover',
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {project.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      {project.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href={project.link}
                    >
                      Get in Touch
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Projects;
