import React, { createContext, useContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

export const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export function ThemeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const toggleDarkMode = () => {
    setDarkMode(prev => {
      const newMode = !prev;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#60a5fa' : '#2563eb', // Modern blue shades
        light: darkMode ? '#93c5fd' : '#3b82f6',
        dark: darkMode ? '#2563eb' : '#1d4ed8',
      },
      secondary: {
        main: darkMode ? '#f472b6' : '#db2777', // Modern pink shades
        light: darkMode ? '#f9a8d4' : '#ec4899',
        dark: darkMode ? '#db2777' : '#be185d',
      },
      background: {
        default: darkMode ? '#0f172a' : '#ffffff', // Slate dark/white
        paper: darkMode ? '#1e293b' : '#f8fafc', // Slate darker/light
        card: darkMode ? '#334155' : '#ffffff', // Slate medium/white
        elevated: darkMode ? '#475569' : '#f1f5f9', // Slate light/lighter
      },
      text: {
        primary: darkMode ? '#f8fafc' : '#0f172a', // Slate lightest/darkest
        secondary: darkMode ? '#cbd5e1' : '#475569', // Slate light/medium
      },
      divider: darkMode ? '#334155' : '#e2e8f0', // Subtle divider colors
      error: {
        main: '#ef4444', // Modern red
      },
      warning: {
        main: '#f59e0b', // Modern amber
      },
      info: {
        main: '#3b82f6', // Modern blue
      },
      success: {
        main: '#10b981', // Modern green
      }
    },
    typography: {
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      h1: {
        fontWeight: 800,
        fontSize: 'clamp(2.5rem, 5vw, 3.75rem)',
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
      },
      h2: {
        fontWeight: 700,
        fontSize: 'clamp(2rem, 4vw, 3rem)',
        lineHeight: 1.3,
        letterSpacing: '-0.01em',
      },
      h3: {
        fontWeight: 700,
        fontSize: 'clamp(1.5rem, 3vw, 2.25rem)',
        lineHeight: 1.3,
        letterSpacing: '-0.01em',
      },
      h4: {
        fontWeight: 600,
        fontSize: 'clamp(1.25rem, 2vw, 1.75rem)',
        lineHeight: 1.4,
      },
      h5: {
        fontWeight: 600,
        fontSize: 'clamp(1.125rem, 1.5vw, 1.5rem)',
        lineHeight: 1.4,
      },
      h6: {
        fontWeight: 600,
        fontSize: 'clamp(1rem, 1.25vw, 1.25rem)',
        lineHeight: 1.4,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.7,
        letterSpacing: '0.00938em',
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.6,
        letterSpacing: '0.01071em',
      },
      button: {
        textTransform: 'none',
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 12,
    },
    spacing: factor => `${0.25 * factor}rem`,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              width: '0.4rem',
            },
            '&::-webkit-scrollbar-track': {
              background: darkMode ? '#1e293b' : '#f1f5f9',
            },
            '&::-webkit-scrollbar-thumb': {
              background: darkMode ? '#475569' : '#cbd5e1',
              borderRadius: '0.4rem',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: '0.5rem 1.5rem',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-1px)',
            },
          },
          contained: {
            boxShadow: darkMode 
              ? '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)'
              : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            '&:hover': {
              boxShadow: darkMode 
                ? '0 6px 8px -1px rgba(0, 0, 0, 0.4), 0 3px 6px -1px rgba(0, 0, 0, 0.3)'
                : '0 6px 8px -1px rgba(0, 0, 0, 0.15), 0 3px 6px -1px rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            boxShadow: darkMode 
              ? '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)'
              : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: darkMode 
                ? '0 10px 15px -3px rgba(0, 0, 0, 0.4), 0 4px 6px -2px rgba(0, 0, 0, 0.3)'
                : '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? 'rgba(15, 23, 42, 0.8)' : 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            boxShadow: 'none',
            borderBottom: `1px solid ${darkMode ? '#334155' : '#e2e8f0'}`,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            transition: 'all 0.2s ease-in-out',
          },
          elevation1: {
            boxShadow: darkMode 
              ? '0 1px 3px rgba(0, 0, 0, 0.3)'
              : '0 1px 3px rgba(0, 0, 0, 0.1)',
          },
          elevation2: {
            boxShadow: darkMode 
              ? '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)'
              : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              textDecoration: 'none',
              opacity: 0.8,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-1px)',
            },
          },
        },
      },
    },
  }), [darkMode]);

  const value = {
    darkMode,
    toggleDarkMode,
    theme,
  };

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
