import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Box } from '@mui/material'

import Navbar from './components/Navbar.jsx'
import Hero from './components/Hero.jsx'
import About from './components/About.jsx'
import Projects from './components/Projects.jsx'
import Contact from './components/Contact.jsx'

import { ThemeProvider, useThemeContext } from './context/ThemeContext.jsx'

function AppContent() {
  const { theme } = useThemeContext()

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Navbar />
      <Hero />
      <About />
      <Projects />
      <Contact />
    </Box>
  )
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  )
}

export default App
